<template>
  <div>
    <span class="ml-2"> {{ $t("password") }} </span>
    <v-row class="text-left mb-sm-n4 mb-n10">
      <v-col cols="12" sm="8" md="5">
        <v-form class="d-flex">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div style="height: 33px" v-bind="attrs" v-on="on">
                <v-btn
                    style="margin-top: 2px"
                    class="ml-3"
                    @click="getChangePasswordUrl"
                    :loading="isChangePasswordLoading"
                    :disabled="!isChangePasswordEnabled"
                >
                  {{ $t("buttons.changePassword") }}
                  <template v-slot:loader>
                        <span class="custom-loader">
                          <v-icon light>mdi-cached</v-icon>
                        </span>
                  </template>
                </v-btn>
              </div>
            </template>
            <span v-if="isChangePasswordEnabled"> {{ $t("toolTips.changePasswordRedirect") }} </span>
            <span v-else> {{ $t("toolTips.changePasswordEnabled") }} </span>
          </v-tooltip>
        </v-form>
      </v-col>
    </v-row>
    <v-snackbar right top app color="success" v-model="passwordChangedSuccess">
      {{ $t("notificationSnackbars.passwordChangedSuccess") }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="passwordChangedSuccess = false">
          {{ $t("buttons.close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar right top app color="error" v-model="passwordChangedError">
      {{ $t("notificationSnackbars.passwordChangedError") }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="passwordChangedError = false">
          {{ $t("buttons.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {changePassword} from "../../../services/backendService";

export default {
  data() {
    return {
      loginConnection: "",

      isChangePasswordLoading: false,
      passwordChangedError: false,
      passwordChangedSuccess: false,
    };
  },
  computed: {
    isChangePasswordEnabled() {
      return this.$store.state.user.userAuth0Id.startsWith("auth0|");
    }
  },
  methods: {
    async getChangePasswordUrl() {
      this.isChangePasswordLoading = true;
      const response = await changePassword();
      if (!response) {
        this.passwordChangedError = true;
        // Stop showing loading button only when there is an error (no redirect)
        this.isChangePasswordLoading = false;
      } else {
        this.passwordChangedSuccess = true;
        window.location.href = response;
      }
    }
  },
}
</script>

<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
